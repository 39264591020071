export default {
    ROUTER_TAG: {
        HOME: "HomeView",
        BOOKMARKS: "BookmarksView",
        SERVICES: "ServicesView",
        INITIALIZE: "InitializeView",
        SETTINGS: "SettingsView",
        PRIVACY: "PrivacyView",
        ABOUT: "AboutView",
        NOT_FOUND: "NotFoundView"
    }
};
