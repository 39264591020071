import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const darkValue = localStorage.getItem("dark");

export default new Vuetify({
  theme: {
    dark: darkValue === "true",
    themes: {
      light: {
        background: colors.shades.white,
        primary: colors.blue.darken1,
        secondary: colors.blue.lighten4,
        accent: colors.indigo.base,
        services: colors.shades.white,
        login: colors.grey.lighten5,
      },
      dark: {
        background: "#121212",
        primary: colors.blue.darken1,
        secondary: colors.blue.lighten4,
        accent: colors.indigo.base,
        services: colors.grey.darken3,
        login: colors.grey.darken4,
      },
    },
  },
});
