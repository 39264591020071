<template>
  <v-app>
    <v-app-bar :prominent="isSearch && !isMini" app color="background" flat>
      <v-btn v-if="isLogined || !isSearch" depressed icon @click="back">
        <v-icon v-if="isSearch">mdi-chevron-right</v-icon>
        <v-icon v-else>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer />
      <card-block v-show="isSearch" />
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import CardBlock from "@/components/CardBlock";

export default {
  name: "App",
  components: { CardBlock },
  created() {
    this.$passport().
      then(() => {
        this.$store.commit("setLogined", true);
      }).
      catch(() => {
        this.$store.commit("setLogined", false);
      });
  },
  computed: {
    isInit() {
      return this.$store.state.nickname === ""
    },
    isLogined() {
      return this.$store.state.isLogined;
    },
    isSearch() {
      return this.$route.path === "/"
    },
    isMini() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  watch: {
    isInit() {
      this.$router.push("/initialize")
    },
  },
  methods: {
    back() {
      if (this.$route.path === "/") {
        this.$router.push("/bookmarks")
      } else if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push("/")
      }
    },
  },
}
</script>
