<template>
  <div>
    <v-btn :loading="isLogined === null" depressed rounded @click="cardTrigger">
      <v-icon v-if="isLogined">mdi-cloud-outline</v-icon>
      <v-icon v-else>mdi-cloud-off-outline</v-icon>
    </v-btn>
    <v-card v-if="isLogined !== null" v-show="isCardShown" id="user-card" v-click-outside="cardOutside">
      <div v-if="isLogined">
        <v-card-title>Hello, {{ nickname }}.</v-card-title>
        <v-card-text>
          <a v-if="isSettingsAvailable" @click="$router.push('/settings')">App Settings</a>
          <span v-else class="grey--text">App Settings</span><br />
          <a @click="goLinks(1)">Account Center</a>
        </v-card-text>
        <v-card-actions>
          <v-btn color="services" block depressed link to="/services">Services</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="login" block depressed @click="goLinks">Logout</v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-actions>
          <v-btn color="services" block depressed link to="/services">Services</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="login" block depressed @click="goLinks">Login</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CardBlock",
  created() {
    this.$passport().
      then(() => {
        this.getStatus();
      }).
      catch(() => {
        this.isLogined = false;
      });
  },
  data: () => ({
    isCardShown: false,
    isOutsideClicked: false,
    isLogined: null,
    nickname: "Unknown",
  }),
  methods: {
    cardOutside() {
      if (this.isCardShown) {
        this.isCardShown = false;
        this.isOutsideClicked = true;
        setTimeout(() => {
          this.isOutsideClicked = false;
        }, 300);
      }
    },
    cardTrigger() {
      setTimeout(() => {
        if (this.isOutsideClicked) {
          this.isOutsideClicked = false;
        } else {
          this.isCardShown = !this.isCardShown;
        }
      }, 90);
    },
    goLinks(specific) {
      let target;
      switch (specific) {
        case 1: {
          target = process.env.VUE_APP_ACCOUNT_CENTER_URL
          break;
        }
        default: {
          target = !this.isLogined
            ? `${process.env.VUE_APP_LOGIN_URL}?refer=${location.href}`
            : `${process.env.VUE_APP_LOGOUT_URL}?refer=${location.href}`
        }
      }
      this.isLogined = null;
      this.isCardShown = false;
      this.isOutsideClicked = false;
      location.assign(target);
    },
    async getStatus() {
      try {
        const response = await this.$appClient.get("/pattern/profile");
        this.isLogined = response.status === 200;
        const nickname = response.data.nickname;
        if (this.isLogined) {
          this.$store.commit("setNickname", nickname);
        }
        if (nickname === "") {
          this.nickname = "my master";
        } else {
          this.nickname = nickname;
        }
      } catch (e) {
        console.log(e);
        this.isLogined = false;
      }
    },
  },
  computed: {
    isisLogined() {
      return this.$store.state.isLogined;
    },
    isSettingsAvailable() {
      return this.$store.state.nickname !== "";
    }
  }
};
</script>

<style scoped>
#user-card {
  width: 200px;
  position: absolute;
  right: 10px;
}
</style>
