"use strict";

import Vue from "vue";
import axios from "axios";

export const nodeUUID = "6ca2e159-9b25-4922-8433-c9ce498db024";

export const passportHeaderName = "X-Shiranui-User-Passport";

const client = axios.create({
    baseURL: process.env.VUE_APP_SHIRANUI_API_HOST || "",
    timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
});

const passportPromise = new Promise((resolve, reject) => {
    client
        .post("passport/activity", {
            uuid: nodeUUID,
        })
        .then(({data}) => {
            resolve(data);
        })
        .catch((e) => {
            reject(e);
        });
});

export const getPassport = () => passportPromise;

const extension = {
    install: (Vue) => {
        Vue.prototype.$passport = getPassport;
    },
};

Vue.use(extension);

export default extension;
