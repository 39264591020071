import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLogined: null,
        nickname: null,
    },
    mutations: {
        setLogined(state, value) {
            state.isLogined = value
        },
        setNickname(state, value) {
            state.nickname = value
        },
    },
});
