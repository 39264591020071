import Vue from 'vue';
import VueRouter from 'vue-router';

import constant from "@/const.js";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: constant.ROUTER_TAG.HOME,
        component: () => import('@/views/HomeView')
    },
    {
        path: '/lena',
        name: constant.ROUTER_TAG.LENA,
        component: () => import('@/views/LenaView')
    },
    {
        path: '/bookmarks',
        name: constant.ROUTER_TAG.BOOKMARKS,
        component: () => import('@/views/BookmarksView')
    },
    {
        path: '/services',
        name: constant.ROUTER_TAG.SERVICES,
        component: () => import('@/views/ServicesView')
    },
    {
        path: '/initialize',
        name: constant.ROUTER_TAG.INITIALIZE,
        component: () => import('@/views/InitializeView')
    },
    {
        path: '/settings',
        name: constant.ROUTER_TAG.SETTINGS,
        component: () => import('@/views/SettingsView')
    },
    {
        path: '/privacy',
        name: constant.ROUTER_TAG.PRIVACY,
        component: () => import('@/views/PrivacyView')
    },
    {
        path: '/about',
        name: constant.ROUTER_TAG.ABOUT,
        component: () => import('@/views/AboutView')
    },
    {
        path: '*',
        name: constant.ROUTER_TAG.NOT_FOUND,
        component: () => import('@/views/NotFoundView')
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_ROUTER_GUARD !== 'on') {
        next();
        return;
    }
    next();
});

export default router;
