import Vue from 'vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

import './plugins/shiranui'
import './plugins/axios'
import './registerServiceWorker'

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
