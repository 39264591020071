"use strict";

import Vue from 'vue';
import axios from "axios";

import {
    passportHeaderName,
    getPassport,
} from './shiranui';

const client = axios.create({
    baseURL: process.env.VUE_APP_GARDEN_API_HOST || "",
    timeout: 60 * 1000,
});

client.interceptors.request.use(
    async function (config) {
        try {
            const { passport } = await getPassport();
            if (passport) {
                config.headers[passportHeaderName] = passport;
            }
        } catch (e) {
            console.warn(e.message);
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const extension = {
    install: (Vue) => {
        Vue.prototype.$appClient = client;
    },
};

Vue.use(extension)

export default extension;
